<template>
    <header class="app-header" >
        <HeaderLogo />
        <div class="header-account-panel"> 
            <!-- <NotificationsWidget /> -->
            <AccountWidget @logout="logout()"/>
        </div>
    </header>
</template>

<script>
// import NotificationsWidget from '@/components/NotificationsWidget.vue';
import AccountWidget from '@/components/AccountWidget.vue';
import sessionService from '@/services/sessionService.js';
import HeaderLogo from '@/components/HeaderLogo.vue';
export default {
    components: {AccountWidget, HeaderLogo},
    methods: {
        logout: sessionService.revokeToken
    }
}
</script>

<style lang="scss">
    .app-header{
        // user-select: none;
        border-bottom: 1px solid var(--borderColor);
        padding: 0.8rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .widget-base{
            display: flex;
            align-items: center;
            cursor: pointer;
            .widget-title{
                font-size: 0.875rem;
                margin-left: 0.5rem;
                @extend .secondary-font;
                
                &:hover{
                    color: var(--mainColor);
                }
            }
            .widget-icon{
                cursor: pointer;
                border: 1px solid var(--borderInput);
                border-radius: 50%;
                width: 28px;
                height: 28px;
                text-align: center;
                &:hover{
                    background: var(--bgLight);
                }

                >svg{
                    margin-top: 0.25em;
                }
            }
        }
    }
    .header-account-panel{
        display: flex;
        align-items: center;
        >*{
            margin-right: 0.5rem;
        }
    }
    
</style>