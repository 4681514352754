<template>
    <div>
        <nav class="app-nav" :class="{'isOpen': wideNav, 'open': openNav}">
            <div v-for="link in navItems" :key="link.name" class="nav-group">
                <router-link :to="{ name: link.routeName }" >
                    <span :aria-label="link.name" class="nav-item" :class="{'active' : activeGroup === link.name}" >
                        <BaseIcon :name="link.icon" size="20" />
                    </span>
                </router-link >
                <div class="nav-group-container">
                    <router-link :to="{ name: link.routeName }" class="nav-group-header mb-05">
                        {{link.name}} <sup v-if="link.label">{{link.label}}</sup>
                        <!-- <BaseIcon v-if="link.label === '(beta)'" name="question" class="action-icon ml-05" :title="$options.betaDisclaimer"/> -->
                    </router-link>
                    <div class="nav-group-list">
                        <router-link :to="{ name: innerLink.routeName }"
                            v-for="innerLink in link.children" :key="innerLink.name" class="nav-list-item" :class="{'link-active' : isActiveRoute(innerLink)}"
                        >
                            <div  @click="clickHandler(innerLink.routeName)">{{innerLink.name}}</div>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="nav-item-container nav-toggle">
                <span class="nav-item" @click="toggleWide">
                    <BaseIcon :name="wideNav ? 'chevrons_left' : 'chevrons_right'" size="12" />
                </span>
            </div>
        </nav>
        <div class="mobile-nav">
            <button @click="openNav = !openNav" class="mobile-toggle">
                <BaseIcon size="22" :name="openNav ? 'close' : 'menu'" />
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            navItems: {
                type: Array,
                required: true,
            }
        },
        betaDisclaimer: `<h3>BETA DISCLAIMER</h3> 
        Please note that RMS VPN is still in beta phase and is still undergoing final testing before its official release. The functionality is provided on an “as is” and “as available” basis. As the functionality is still in BETA, we reserve the right to restart / disable / delete any VPN Hub if we deem it necessary.
        We do not give any warranties, whether express or implied, as to the stability or usability of the functionality.
        
        Should you encounter any bugs, glitches or other problems with RMS VPN, please let us know immediately so we can rectify them accordingly. You can do so by using our crowd support forum - <a href="https://community.teltonika-networks.com" class="link">https://community.teltonika-networks.com</a> .
        
        Your help in this regard is greatly appreciated!`,
        data(){
            return {
                wideNav: true,
                openNav: false,
            }
        },
        mounted(){
            if(localStorage.getItem('wideNav')){
                this.wideNav = JSON.parse(localStorage.getItem('wideNav'));
            }
        },
        watch:{
            openNav() {
                if(this.openNav){
                    window.scrollTo(0, 0);
                }
                this.openNav ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll');
            },
            $route(){
                this.openNav = false;
            }
        },
        computed:{
            activeGroup(){
                let activeName;
                this.navItems.forEach(group => {
                    group.children.forEach(child => {
                        if(this.isActiveRoute(child)){
                            activeName = group.name;
                        }
                    });
                });
                return activeName;
            }
        },
        methods: {
            toggleWide(){
                this.wideNav = !this.wideNav;
                localStorage.setItem('wideNav', this.wideNav);
                this.$eventHub.$emit('open-nav', this.wideNav);
            },
            clickHandler(routerName){
                if(this.$route.name === routerName){
                    window.location = window.location.pathname;
                }
            },
            isActiveRoute(link){
                if(this.$route.name === link.routeName) return true;
                if(link.root && this.$route.path.includes(link.root)) return true;
                return false;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .link-active {
        color: var(--mainColor);
    }
    .app-nav {
        top: 0;
        bottom:0;
        position: fixed;
        z-index: 10;
        --mainNavColor: var(--mainColor);
        background: linear-gradient(90deg,var(--mainNavColor) 50px,var(--bgLight) 0%);
        box-shadow: none;
        display: -webkit-box;
        display: flex;
        flex-direction: column;
        padding-top: 70px;
        height: 100vh;
        .nav-group {
            position: relative;
            &.active {
                .nav-group-icon {
                    background: var(--navHighlight);
                }
                .nav-group-header {
                    @extend .link-active;
                }
            }
            .nav-group-icon {
                position: absolute;
                top: 0;
                left: -$nav_colored_width;
                width: $nav_colored_width;
                text-align: center;
                padding: 15px;
                line-height: 1;
            }
            .nav-list-item {
                @extend .secondary-font;
                display: block;
                font-size: 0.825rem;
                line-height: 1.5;
                color: var(--textLight);
                transition: all 0.2s;
                white-space: nowrap;
                cursor: pointer;
                &:hover {
                    color: var(--textDark);
                    // transform: translateX(0.5em);
                    padding-left: .5em;
                }
                &.link-active {
                    color: var(--mainColor);
                }
            }
            .nav-group-container {
                background: var(--bgLight);
                color: var(--mainColor);
                position: absolute;
                left: 100%;
                z-index: -1;
                top: 0;
                padding: 1rem 1.5rem;
                text-align: initial;
                opacity: 0;
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
                pointer-events: none;
                transform-origin: left;
                .nav-group-header {
                    @extend .secondary-font;
                    white-space: nowrap;
                    color: var(--mainColor);
                    display: block;
                    sup{
                        font-size: 0.7rem;
                        vertical-align: top;
                        line-height: 1.4em;
                    }
                }
            }
        }
        &.isOpen {
            width: var(--navWidth);
            overflow: auto;
            .nav-group:hover .nav-group-container {
                box-shadow: none;
            }
            .nav-group-container {
                transition: opacity 0.3s;
                position: static;
                z-index: 1;
                opacity: 1;
                border-radius: 0;
                box-shadow: none;
                float: right;
                width: calc(100% - 50px);
                pointer-events: all;
            }
        }
        &:not(.isOpen) {
            .nav-group{
                &:hover .nav-group-container {
                    opacity: 1;
                    z-index: inherit;
                    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.08);
                    pointer-events: all;
                    animation: 0.2s flip-side forwards alternate;
                }
                &:hover{
                    .nav-item{
                        color: var(--mainColor);
                        background-color: var(--bgColor);
                    }
                }
            }
            .nav-item {
                &.active:hover,
                &:hover {
                    color: var(--mainColor);
                    background-color: var(--bgColor);
                }
            }
        }
        .nav-item-container {
            display: block;
            margin-top: auto;
            .nav-item {
                float: left;
            }
        }
        .nav-item {
            color: white;
            padding: 0.84em;
            text-decoration: none;
            height: 50px;
            width: 50px;
            text-align: center;
            position: relative;
            display: inline-block;
            font-size: 19px;
            
            &.active {
                background-color: var(--navHighlight);
                &::after {
                    content: "";
                    display: block;
                    width: 0px;
                    height: 0px;
                    border-top: 5px solid transparent;
                    border-bottom: 5px solid transparent;
                    border-right: 5px solid var(--bgLight);
                    position: absolute;
                    top: 1.4rem;
                    right: 0px;
                }
            }
        }
    }
    .nav-toggle .nav-item{
        cursor: pointer;
        &:hover{
            background-color: var(--navHighlight);
        }
    }
</style>