import {timeout} from '@/utils'

let entityMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '/': '&#x2F;',
    '`': '&#x60;',
    '=': '&#x3D;'
};

export default  {
    bind: function (el, binding){
        if (!binding.value) return;
        el.addEventListener('mouseenter', function (event) {
            setTooltip(el, binding.value, event);
        });
        el.addEventListener('mouseleave', removeTooltip);
    },
    componentUpdated: function (el, binding) {
        // if (!binding.value) return;
        if(!el) return;
        el.onmouseenter = function (event) {
            setTooltip(el, binding.value, event, el.dataset);
        };

        if(!el.dataset.tooltipRef) return;
        updateTooltipText(binding.value, el.dataset.tooltipRef);
    }
}

function escapeHTML(string) {
    return String(string).replace(/[&<>"'`=/]/g, function (s) {
        return entityMap[s];
    });
}

export async function setTooltip(el, string, e){
    let escape = el.attributes?.hasOwnProperty('ignore-escaping') ? false : true;
    if(!string) return;
    if(!el) return;
    removeTooltip();

    let text;
    if(escape) { text = escapeHTML(string) }
    else { text = string }

    const $tooltip = document.createElement('div');
    const $tooltipDimension = el.getBoundingClientRect();

    $tooltip.setAttribute('class', 'tooltip');
    $tooltip.setAttribute('id', 'current_tooltip');
    $tooltip.innerHTML = text;

    if(el.dataset.tooltipRef){
        $tooltip.setAttribute('tooltipRef', el.dataset.tooltipRef);
    }
    
    const isShortText = text.length < 50;
    let yPos, xPos, minSpace;
    let xPosKey = 'left';
    const isFormLabel = el.nodeName === 'LABEL' && el.parentNode.classList.contains("form-field");

    if (isFormLabel || isShortText) {
        if(isShortText) $tooltip.classList.add('short');
        yPos = $tooltipDimension.top - (16 * 2) + window.scrollY;
        if(e){
            xPos = e.clientX;
        }else{
            xPos = $tooltipDimension.left + ($tooltipDimension.width / 2);
        }
       
        minSpace = text.length * 3;
        if(minSpace < 24){
            minSpace = 24;
        }

        if ((window.innerWidth - xPos) < minSpace) { // if no space to the right
            yPos = yPos + $tooltipDimension.height + 8;
            xPosKey = 'right';
            xPos = window.innerWidth - $tooltipDimension.left + ($tooltipDimension.width / 2);
            $tooltip.style.transform = 'translateX(0)';
            if(e){
                xPos = window.innerWidth - e.clientX;
            }
        }

        if ($tooltipDimension.top < 40){ // if no space to the top
            yPos = $tooltipDimension.top + ($tooltipDimension.height * 2) + window.scrollY;
        }


    } else {
        yPos = $tooltipDimension.top - 30 + window.scrollY;
        xPos = $tooltipDimension.left + $tooltipDimension.width;
        minSpace = 300;

        if ((window.innerWidth - xPos) < minSpace) {
            xPosKey = 'right';
            xPos = window.innerWidth - $tooltipDimension.left;
        }
    }

    $tooltip.style.top = yPos + 'px';
    $tooltip.style[xPosKey] = xPos + 'px';
    document.body.appendChild($tooltip);
    await timeout(5);
    $tooltip.classList.add('visible');
}

export function removeTooltip(){
    const elemToRemove = document.getElementById('current_tooltip');
    if (elemToRemove) {
        elemToRemove.parentNode.removeChild(elemToRemove);
    }
}

function updateTooltipText(text){
    const currentTooltip = document.querySelector('#current_tooltip');
    if (currentTooltip) {
        currentTooltip.innerHTML = text;
    }
}