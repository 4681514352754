var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "nav",
      {
        staticClass: "app-nav",
        class: { isOpen: _vm.wideNav, open: _vm.openNav },
      },
      [
        _vm._l(_vm.navItems, function (link) {
          return _c(
            "div",
            { key: link.name, staticClass: "nav-group" },
            [
              _c("router-link", { attrs: { to: { name: link.routeName } } }, [
                _c(
                  "span",
                  {
                    staticClass: "nav-item",
                    class: { active: _vm.activeGroup === link.name },
                    attrs: { "aria-label": link.name },
                  },
                  [_c("BaseIcon", { attrs: { name: link.icon, size: "20" } })],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "nav-group-container" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-group-header mb-05",
                      attrs: { to: { name: link.routeName } },
                    },
                    [
                      _vm._v(" " + _vm._s(link.name) + " "),
                      link.label
                        ? _c("sup", [_vm._v(_vm._s(link.label))])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "nav-group-list" },
                    _vm._l(link.children, function (innerLink) {
                      return _c(
                        "router-link",
                        {
                          key: innerLink.name,
                          staticClass: "nav-list-item",
                          class: {
                            "link-active": _vm.isActiveRoute(innerLink),
                          },
                          attrs: { to: { name: innerLink.routeName } },
                        },
                        [
                          _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.clickHandler(innerLink.routeName)
                                },
                              },
                            },
                            [_vm._v(_vm._s(innerLink.name))]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        _c("div", { staticClass: "nav-item-container nav-toggle" }, [
          _c(
            "span",
            { staticClass: "nav-item", on: { click: _vm.toggleWide } },
            [
              _c("BaseIcon", {
                attrs: {
                  name: _vm.wideNav ? "chevrons_left" : "chevrons_right",
                  size: "12",
                },
              }),
            ],
            1
          ),
        ]),
      ],
      2
    ),
    _c("div", { staticClass: "mobile-nav" }, [
      _c(
        "button",
        {
          staticClass: "mobile-toggle",
          on: {
            click: function ($event) {
              _vm.openNav = !_vm.openNav
            },
          },
        },
        [
          _c("BaseIcon", {
            attrs: { size: "22", name: _vm.openNav ? "close" : "menu" },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }