var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { staticClass: "app-header" },
    [
      _c("HeaderLogo"),
      _c(
        "div",
        { staticClass: "header-account-panel" },
        [
          _c("AccountWidget", {
            on: {
              logout: function ($event) {
                return _vm.logout()
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }