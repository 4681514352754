const tokens = {
    '#': { pattern: /[\d\s]/ },
    '*': { pattern: /[0-9a-zA-Z]/ },
    'S': { pattern: /[a-zA-Z]/ },
    'F': { pattern: /[0-9a-fA-F]/, transform: v => v.toLocaleUpperCase() },
    'A': { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
    'a': { pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase() },
    '!': { escape: true }
}

function event(name){
    var evt = document.createEvent('Event')
    evt.initEvent(name, true, true)
    return evt;
}

export default function(el, binding){
    if(!binding.value) return;

    const config = {
        mask: binding.value,
        tokens: tokens
    }

    el.oninput = function(e){
        if (!e.isTrusted) return;

        el.value = mask(el.value, config.mask, config.tokens);

        el.dispatchEvent(event('input'));
    }

    var newDisplay = mask(el.value, config.mask, config.tokens)
    if (newDisplay !== el.value) {
        el.value = newDisplay
        el.dispatchEvent(event('input'))
    }
}

function mask(value, mask, tokens){

    value = value || '';
    var iMask = 0;
    var iValue = 0;
    var output = '';

    while (iMask < mask.length && iValue < value.length){
        var cMask = mask[iMask]
        var masker = tokens[cMask]
        var cValue = value[iValue]

        if (masker && !masker.escape) {
            if (masker.pattern.test(cValue)) {
                output += masker.transform ? masker.transform(cValue) : cValue
                iMask++
            }
            iValue++
        } else {
            if (masker && masker.escape) {
                iMask++
                cMask = mask[iMask]
            }
            output += cMask
            if (cValue === cMask) iValue++
            iMask++
        }
    }

    var restOutput = ''
    while (iMask < mask.length) {
        let cMask = mask[iMask]
        if (tokens[cMask]) {
            restOutput = ''
            break;
        }
        restOutput += cMask
        iMask++
    }

    return output + restOutput;
}